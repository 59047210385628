import React from "react";
import styled from "styled-components";
import SpotlightList from "../components/SpotlightList";
// Assets
import w3learnmockup from "../assets/w3learnmockup.svg";
import perksmockup from "../assets/perks.svg";
import connectmockup from "../assets/connect.svg";
import gethiredmockup from "../assets/gethired.svg";
import pathtoweb3mockup from "../assets/pathtoweb3.svg";

const perks = [
  {
    title: "W3 Learn Academy",
    description: `Unlock your full potential with W3 Learn Academy, available for free to members of Crypto Tech Women (CTW). 
        Master the skills to thrive as a builder, learner, or creator in the industry with our empowering and educational opportunities for growth and development.`,
    url: "https://w3learnacademy.com",
    img: w3learnmockup,
  },
  {
    title: "Join our community and events",
    description: `Join our global community of learners, builders and creators and connect with like-minded individuals. Share knowledge, network, and inspire each other to reach new heights in the industry. Don't miss out on our IRL events!`,
    url: "https://w3learnacademy.com/perks",
    img: connectmockup,
  },
  {
    title: "Rewards and Perks",
    description: `Unlock a world of savings and tools as a member of CTW with exclusive access to our extensive collection of SAAS products at discounted rates. Don't miss out on this must-have benefit for streamlining your workflow and boosting your productivity.`,
    url: "https://w3learnacademy.com/perks",
    img: perksmockup,
  },
  {
    title: "Learn with us",
    description: `Join the Web3 revolution and learn the fundamentals in just 30 days with CTW's onboarding newsletter series! Each day, you'll receive an easy-to-digest newsletter filled with exciting and engaging information about the basics of Web3.`,
    url: "https://ctw.pallet.com",
    img: pathtoweb3mockup,
  },
  {
    title: "Get hired",
    description: `Stay ahead of the game with exclusive access to CTW's job board and curated talent collective. Find the top web2 and web3 job opportunities and get hired by some of the best companies in the industry.`,
    url: "https://ctw.pallet.com",
    img: gethiredmockup,
  },
];

const Perks = () => {
  return (
    <Main>
      <Title>We help Learners & builders maximize their potential.</Title>
      <SpotlightList itemList={perks} />
    </Main>
  );
};

export default Perks;

const Main = styled.section`
  background-color: white;
  padding: 20px;

  @media (min-width: 768px) {
    padding: 100px 160px;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 60px;
  margin-top: 0;
  text-align: center;
  display: flex;
  align-items: center;
  letter-spacing: -0.6px;
  text-transform: uppercase;
  color: #1a1a1a;

  @media (min-width: 768px) {
    font-size: 60px;
  }
`;
