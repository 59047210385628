import React from "react";
import styled from "styled-components";

const Numbers = () => {
  return (
    <Main>
      <Container>
        <Digit>20k</Digit>
        <Label>Community members</Label>
      </Container>
      <Container>
        <Digit>40k+</Digit>
        <Label>Social media followers</Label>
      </Container>
      <Container>
        <Digit>70+</Digit>
        <Label>Partnerships</Label>
      </Container>
      <Container>
        <Digit>8,888</Digit>
        <Label>Digital Collectibles (NFTs)</Label>
      </Container>
    </Main>
  );
};

export default Numbers;

const Main = styled.div`
  display: flex;
  background-color: #1a1a1a;
  justify-content: space-between;
  padding: 10px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 20px 150px;
  }
`;

const Container = styled.div`
  flex-direction: column;
`;

const Digit = styled.p`
  font-size: 30px;
  color: #1fc2e9;
  margin: 0 10px;

  @media (min-width: 768px) {
    font-size: 60px;
  }
`;

const Label = styled.span`
  color: #ffffff;
  font-size: 12px;
`;
