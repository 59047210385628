import React from "react";
import useMobileDetect from "use-mobile-detect-hook";
import styled from "styled-components";

interface ItemList {
  title: string;
  description: string;
  url: string;
  img: string;
}

const SpotlightList = ({ itemList }: { itemList: ItemList[] }) => {
  const detectMobile = useMobileDetect();

  const productLeft = (item: ItemList) => (
    <ProductItem
      onClick={() => window.open(item.url, "_blank", "noopener,noreferrer")}
    >
      <ProductImg src={item.img} />
      <ProductRightContainer>
        <ProductTitle>{item.title}</ProductTitle>
        <Underline />
        <ProductDescription>{item.description}</ProductDescription>
      </ProductRightContainer>
    </ProductItem>
  );

  const productRight = (item: ItemList) => (
    <ProductItem
      onClick={() => window.open(item.url, "_blank", "noopener,noreferrer")}
    >
      <ProductRightContainer>
        <ProductTitle>{item.title}</ProductTitle>
        <Underline />
        <ProductDescription>{item.description}</ProductDescription>
      </ProductRightContainer>
      <ProductImg src={item.img} />
    </ProductItem>
  );

  return (
    <>
      {itemList.map((item, index) => {
        return index % 2 === 0 || detectMobile.isMobile()
          ? productLeft(item)
          : productRight(item);
      })}
    </>
  );
};

export default SpotlightList;

const Underline = styled.div`
  width: 100%;
  height: 18px;
  background: #1fc2e9;
  margin: 20px 0;
  @media (min-width: 768px) {
    width: 230px;
  }
`;

const ProductImg = styled.img`
  width: 100%;
  height: 360px;

  @media (min-width: 768px) {
    width: 660px;
    height: 360px;
  }
`;

const ProductRightContainer = styled.div`
  @media (min-width: 768px) {
    width: 50%;
    padding: 0 20px;
  }
`;

const ProductTitle = styled.p`
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
  margin: 0;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #1a1a1a;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: unset;
  }
`;

const ProductDescription = styled.p`
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  margin: 0;
  display: flex;
  align-items: center;
  letter-spacing: 0.18px;
  color: #1a1a1a;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const ProductItem = styled.button`
  display: flex;
  cursor: pointer;
  margin: 0;
  background: #fbfbf8;
  border: 0;
  border-radius: 3px;
  flex-direction: column;

  &:hover {
    background: #dff0f4;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    margin: 20px 0;
    padding: 20px;
  }
`;
