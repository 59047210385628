import React from "react";
import styled from "styled-components";
import useMobileDetect from "use-mobile-detect-hook";
// Components
import SocialProof from "../components/SocialProof";
import Numbers from "../components/SocialNumbers";
// Assets
import learn from "../assets/learm.svg";

const LandingPage = () => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();
  return (
    <Main>
      <Hero
        src={
          isMobile
            ? "https://res.cloudinary.com/w3-learn-academy/image/upload/v1684348070/events-mobile_oiudvr.png"
            : "https://res.cloudinary.com/w3-learn-academy/image/upload/v1684348070/event-fw_idqgj5.png"
        }
      >
        <div>
          <Heading>
            {" "}
            Empowering Women+ to Shape the Future
            <br />
            of Tech And Web3
            <br /> Together.
          </Heading>
          <PrimaryButton onClick={() => window.open("/https://discord.com/invite/gUGSYsBHjf", "_blank")}>Join our community</PrimaryButton>
        </div>
      </Hero>
      <SocialProof />
      <AboutWrapper>
        <HeroImage />
        <AboutLeftContainer>
          <AboutContainer>
            <AboutHeadingContainer>
              <Arctext>Our story</Arctext>
              <AboutTitle>
                Learn about <br /> our <br />
                mission
              </AboutTitle>
            </AboutHeadingContainer>
            <AboutDescription>
              The Place for Learners & Builders To Unlock Quality Education, discounted SAAS tools,
              Exclusive events & more.
              <br />
              <br />
              CTW is a community-driven brand dedicated to empowering women+
              with the knowledge and skills they need to succeed in the digital
              era. CTW offers access to top SAAS tools, exclusive IRL events,
              and our educational platform, W3 Learn Academy.
            </AboutDescription>
            <SecondaryButton onClick={() => window.open("/about", "_self")}>
              Read our story
            </SecondaryButton>
          </AboutContainer>
        </AboutLeftContainer>
        <AboutRightContainer />
      </AboutWrapper>
      <Numbers />
      <BenefitsSection>
        <BenefitHeading> What do we offer?</BenefitHeading>
        <BenefitSubtitle>
          Join our community of learners and builders.
        </BenefitSubtitle>
        <BenefitsListWrapper>
          <CardWrapper>
            <Img src={'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685317394/join_qfkasl.jpg'} />
            <CardTitle>Join Us</CardTitle>
            <CardDescription>
              Join our global community of learners, builders and creators and
              connect with like-minded individuals. Share knowledge, network,
              and inspire each other to reach new heights in the industry.
            </CardDescription>
            <CardButton
              onClick={() =>
                window.open(
                  "https://discord.com/invite/gUGSYsBHjf",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              Join Us
            </CardButton>
          </CardWrapper>
          <CardWrapper>
            <Img src={'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685317005/ctw-pfp_tgu9vz.png'} />
            <CardTitle>Digital Collectibles</CardTitle>
            <CardDescription>
              Our collection represents diversity and inclusion for women in
              tech everywhere. Celebrating women from all parts of the world,
              all colors and styles!
            </CardDescription>
            <CardButton
              onClick={() =>
                window.open(
                  "https://opensea.io/collection/cryptotechwomennft",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              Explore collection
            </CardButton>
          </CardWrapper>
          <CardWrapper>
            <Img src={learn} />
            <CardTitle>Learn with us</CardTitle>
            <CardDescription>
              Learn with us with our masterclasses created to equip you with the tools needed to thrive in this
              digital world. Discover new skills, advance your knowledge and
              reach new heights with us.
            </CardDescription>
            <CardButton
              onClick={() =>
                window.open(
                  "https://discord.com/invite/gUGSYsBHjf",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              Start learning
            </CardButton>
          </CardWrapper>
          {/* <CardWrapper>
            <Img src={'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685317399/perks-landing_hod4oz.svg'} />
            <CardTitle>Discover our perks</CardTitle>
            <CardDescription>
              Join our community and take advantage of exclusive benefits from
              discounts on top SaaS products to help you advance your skills to
              access to our educational platform and more!
            </CardDescription>
            <CardButton
              onClick={() =>
                window.open(
                  "https://w3learnacademy.com/perks",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              Discover
            </CardButton>
          </CardWrapper> */}
        </BenefitsListWrapper>
      </BenefitsSection>
      {/* <SpotlightWrapper>
        <SpotlightLabel>Learn and Earn with us</SpotlightLabel>
        <SpotlightHeading>
          Our educational platform:
          <br /> W3 Learn Academy
        </SpotlightHeading>
        <VideoContainer />

        <CtwWrapper>
          <CTW />
        </CtwWrapper>
      </SpotlightWrapper> */}
      <SecondSpotlightWrapper>
        <SecondSpotlightHeading>Some people like us!</SecondSpotlightHeading>
        <SenjaEmbed
          className="senja-frame-embed"
          data-id="96c62309-493f-48f3-a3c0-2b2359050cd8"
        />
      </SecondSpotlightWrapper>
      <EventsSection>
        <EventOverflowCont>
          <EventOverflowTitle>Explore our events</EventOverflowTitle>
          <EventOverflowDescription>
            Explore a list of upcoming events that are open to anyone interested
            in learning more about us. These events include workshops, twitter
            spaces, webinars, and community hangouts on various tech and
            education topics.
          </EventOverflowDescription>
          <EventOverflowButton
            onClick={() =>
              window.open(
                "https://w3learnacademy.com/events",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            EXPLORE EVENTS
          </EventOverflowButton>
        </EventOverflowCont>
      </EventsSection>
      <PartnerContainer>
        <PartnerTitle>Partner With us</PartnerTitle>
        <PartnerDescription>
          Partner with CTW and tap into our community. We offer various
          opportunities, including sponsorship packages to showcase your brand
          or protocol through exciting events, educational courses on our W3
          Learn Academy, or corporate trainings to support your web3 strategy.
        </PartnerDescription>
        <PartnerButton onClick={() => window.open("/partner", "_self")}>
          explore partnerships
        </PartnerButton>
      </PartnerContainer>
    </Main>
  );
};

export default LandingPage;

const CtwWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const CTW = styled.img.attrs({
  src: 'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685316223/ctw-transparent-2_hizmpw.svg',
})`
  display: none;

  @media (min-width: 768px) {
    width: 560px;
    position: absolute;
    bottom: -40px;
    left: -30px;
    display: block;
  }
`;

const Main = styled.section``;

// Senja

const SenjaEmbed = styled.div`
  padding: 20px;
`;

// Hero

const Hero = styled.div<{ src: string }>`
  background-image: ${(props) => props.src && `url(${props.src})`};
  height: 85vh;
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
`;

const Heading = styled.h1`
  color: white;
  margin: 0;
  font-size: 30px;
  margin: 0 15px;
  font-weight: 400;
  text-align: center;
  line-height: 1.4;

  @media (min-width: 768px) {
    font-size: 60px;
    margin: 0 20px;
    text-align: left;
  }
`;

const PrimaryButton = styled.button`
  padding: 10px 35px;
  border-radius: 30px;
  border: 0;
  font-size: 23px;
  margin-top: 20px;
  font-weight: 400;
  margin-left: 15px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #1a1a1a;
  color: white;

  &:hover {
    background-color: #2b2b2b;
  }
`;

const AboutWrapper = styled.div`
  display: block;
  background-color: #1fc2e9e0;
  padding: 15px 0;

  @media (min-width: 768px) {
    display: flex;
    position: relative;
    padding: 0;
  }
`;

// About

const AboutContainer = styled.div`
  text-align: center;
  display: block;
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    width: 70%;
    text-align: left;
    flex-direction: column;
  }
`;

const AboutHeadingContainer = styled.div`
  position: relative;
`;

const AboutLeftContainer = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    padding: 60px 0 100px 150px;
    width: 60%;
    padding: 40px;
  }
`;

const AboutTitle = styled.h2`
  font-size: 30px;
  font-weight: 600;
  margin: 10px 0;
  line-height: 45px;
  text-align: center;

  @media (min-width: 768px) {
    line-height: 80px;
    margin: 0 0 40px 0;
    font-size: 100px;
    text-align: right;
  }
`;

const AboutDescription = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0 0 5px 0;
  padding: 20px;

  @media (min-width: 768px) {
    font-size: 20px;
    text-align: left;
    padding: 0;
  }
`;

const AboutRightContainer = styled.div`
  background-color: white;
`;

const Arctext = styled.p`
  display: none;

  @media (min-width: 768px) {
    font-size: 25px;
    margin: 0;
    font-weight: 600;
  }
`;

const SecondaryButton = styled.button`
  padding: 20px 25px;
  text-align: center;
  border-radius: 30px;
  border: 0;
  font-size: 15px;
  margin: 15px 0 0 0;
  font-weight: 400;
  color: white;
  margin-left: 15px;
  background-color: #1a1a1a;
  text-transform: uppercase;
  align-self: center;
  cursor: pointer;

  &:hover {
    background-color: #2b2b2b;
  }

  @media (min-width: 768px) {
    padding: 20px 35px;
  }
`;

const HeroImage = styled.img.attrs({
  src:'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685316234/hero_xkqlxo.png' ,
})`
  display: block;
  width: 80%;
  background-size: contain;
  margin: 0 auto 0 auto;

  @media (min-width: 768px) {
    width: 656px;
    position: absolute;
    right: 128px;
    top: 45px;
    background-size: cover;
    margin: 0;
    height: 500px;
  }
}
`;

// Benefits

const BenefitsSection = styled.section`
  background-color: white;
  padding: 40px 0;
`;

const BenefitHeading = styled.h3`
  text-align: center;
  margin: 0 0 20px 0;
  font-weight: 400;
  font-size: 25px;

  @media (min-width: 768px) {
    font-size: 70px;
  }
`;

const BenefitSubtitle = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding: 0 0 40px 0;
`;

const BenefitsListWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 768px) {
    flex-wrap: unset;
  }
`;

// Card

const CardWrapper = styled.div`
  padding: 2%;
  background-color: #ffffff;
  margin: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    width: 25%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;

const Img = styled.img.attrs((props: { src: string }) => {
  props.src.concat();
})`
  width: 100%;
  height: 50%;
  object-position: 50% 50%;
  object-fit: cover;
`;

const CardTitle = styled.p`
  font-size: 20px;
  margin: 20px 0 0 0;
  font-weight: 500;
`;

const CardDescription = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  min-height: 138px;
`;

const CardButton = styled.button`
  padding: 10px 35px;
  border-radius: 30px;
  border: 0;
  font-size: 12px;
  margin-top: 20px;
  font-weight: 400;
  margin-left: 15px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  background-color: #1a1a1a;
  color: white;
  
  &:hover {
    background-color: #2b2b2b;
  }

  @media (min-width: 768px) {
    margin-top: 10px;
  }
}
`;

// Learn & earn section

const SpotlightWrapper = styled.section`
  background: url(https://res.cloudinary.com/w3-learn-academy/image/upload/v1685316246/background_avlywg.svg);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    padding: 40px 0;
  }
`;

const SpotlightLabel = styled.p`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1.4px;

  color: #1a1a1a;
`;

const SpotlightHeading = styled.p`
  color: black;
  font-weight: 500;
  font-size: 30px;
  line-height: 66px;
  text-align: center;
  letter-spacing: 0.55px;
  margin: 10px 0;

  @media (min-width: 768px) {
    font-size: 55px;
    line-height: 66px;
  }
`;

const VideoContainer = styled.img`
height: 375px;
  width: 100%;
  background-size: cover;
  background-image: url(https://res.cloudinary.com/w3-learn-academy/image/upload/v1685317166/learnmobile_gcgpbx.svg);
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    background-image: url(https://res.cloudinary.com/w3-learn-academy/image/upload/v1685317167/learnwithus_g4yxwi.svg);
    width: 760px;
    height: 400px;
  }
}
`;

// Newsletter Spotlight

const SecondSpotlightWrapper = styled.section`
  padding: 40px 0;
  background-color: white;
`;

const SecondSpotlightHeading = styled.p`
  color: #1a1a1a;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.55px;
  margin: 10px 0;

  @media (min-width: 768px) {
    font-size: 55px;
    line-height: 66px;
  }
`;

// Events

const EventsSection = styled.section`
  background-image: url(https://res.cloudinary.com/w3-learn-academy/image/upload/v1685316246/background_avlywg.svg);
  background-size: cover;
  height: 60vh;
  position: relative;
`;

const EventOverflowCont = styled.div`
  position: block;
  background-color: white;
  padding: 20px;

  @media (min-width: 768px) {
    position: absolute;
    padding: 40px;
    width: 500px;
    bottom: -70px;
    right: 150px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;

const EventOverflowTitle = styled.p`
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  align-items: center;
  text-align: center;
  color: #1a1a1a;

  @media (min-width: 768px) {
    font-size: 75px;
    line-height: 75px;
  }
`;

const EventOverflowDescription = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  align-items: center;
  color: #1a1a1a;
`;

const EventOverflowButton = styled.button`
  padding: 10px 35px;
  border-radius: 30px;
  border: 0;
  font-size: 15px;
  margin-top: 20px;
  font-weight: 400;
  margin-left: 15px;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  cursor: pointer;

  background-color: #1a1a1a;
  color: white;

  &:hover {
    background-color: #2b2b2b;
  }
`;

// Partner with us

const PartnerContainer = styled.section`
  background-color: white;
  padding: 40px;

  @media (min-width: 768px) {
    padding: 40px 120px 80px 120px;
  }
`;

const PartnerTitle = styled.p`
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
  align-items: center;
  text-align: center;
  color: #000000;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 61px;
  }
`;

const PartnerDescription = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
`;

const PartnerButton = styled.button`
  margin: 20px auto 0 auto;
  display: flex;
  padding: 20px 35px;
  text-align: center;
  border-radius: 30px;
  border: 0;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  align-self: center;
  cursor: pointer;

  background-color: #1a1a1a;
  color: white;

  &:hover {
    background-color: #2b2b2b;
  }
`;
