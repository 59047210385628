import React from "react";
import styled from "styled-components";
import Numbers from "../components/SocialNumbers";
import serena from "../assets/serena.jpeg";

const twLogo =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqElEQVR4nO2YzWsTQRjGQ0YQQRQEEQQRPAp6ET158uClLV4ERfGmoHjx4ElBhFIQRfwHRBQP2a22asVaommiYFra0mKDtIJai2CbtrY2O7ud/Zh9ZDcmUpq6u/mYTWEfeE8JzPOb93l3ZycWixQpUqSmFkmoJ+ISzRBZoUSmEFMKjcs0TSS1rTbzknJLnGlauSSlo+qdD928/LcStDUwQDE2TWBepojLtD94BySqhG2clGNEC8EBwjYtr64IgEQdoBs3Qps7Ka6O6ZgocBgcmGM2un+YOPJGc3/f+pTidJbhZk4XA3Ajp5cX96pNMnXNVpLOgdSsBc0Ccr85dj1XxQB8UTgWdBsH+7whzg8xeOntrIULQwxXRgV1oGDa7sJ5ZuNYeuW/AIMLHH40rdrY2yOoA9l5q7ywZQPXPupuVCr9l1ZOzypxG9j3cn3zdQc4+WFtLN7NWTicXBspP7JsiH8KPZyqvLWZvIVLIwwHXmvY1uUPIM9ssQDpvIWLwwx9M/+iVIvGl7hYgP58fYyX9Pi7KRbAeQc4ua2Xzg4w8TPQ8n4F86x2CtUCdj5TxQNseUKxv1fDq5+1xenupOFpviEA0rSPB7yHlg0bu1+o4QBs71Lx4JuJWkJ0Kuud/YYBlOpQUsOdSQNjixxLhn+ce5/9RYc0GqBUzour1+c8tH8y1j16EBEAzvndOXgdz6zg+rju+8DmfAecG/QfG9IIgKMpDckZK1D2nQPd7QkDO7r9DSwREaE9PSouj+h4NGVidJG75xnGix8pzk4P/+K4/9XEmQHmDny1xomoGWh0xSIAeaN1QGqiq0WZLgcGcO/nwzcOp+ISTVXRAbUtbOOkVJ20JTBAEULpCN28rLRXZb4MkaCtzv280JmQqOLEpuqdjxQpUqSYKP0BoMaOdbh640oAAAAASUVORK5CYII=";

const linkedInLogo =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACEklEQVR4nO2YO08CQRDH11jYGisTW7X1E1jZKcTSL2EQJHJbEAsJiZ2FhZ2FpbWFDY/IO0YN3GIEiQWFxhhBER8oMGZvFVC4wJ3mbi/ZfzIh7GyW+e3Mzh2LkJCQkBDfkrKLSCJhhEkVYQIGWRVhEkKSbP9b8FjeNDBoUDG//p03P3hgJtt0AChlw0HwBJBEgjoA5CeOMlDRDqC2mCcNyJUEtBJjn/S7ERCapRL8tC8FR5dlqNbqECmUYWojZQyEZvVaxJVUgu5UOF9mmbAEwEpM2flOPb3VWTlZAsBl9Qx42BmgEDQToXwJJi11BrDVu5AzAcgR7TY63s8vZQC5j9tznF8bsHZK+7xBAI4I9BId7+ef8CZhK1iE7HUVXt8b8PDyoZTi8n4ORtyJ/pk0E2DGn4LbSg3UdFKswLg3ybLEI0Dh7gX6KZQvwZArzifAoJrfyaiXkpkAjWZTqf+57TQs7WYhcFHqOW8vdcMOOm8A6wdXLDBa4540DK8m4PD8vmte7va53dF4AhjD8Z+t0pOG2a2zrnmPrx/qryVmATSbHf6WyTAqJVTmRvkCYP6o9rWwACAiA98SJYTFISaiCyHRRv/lH1mcPWh+Gx0fxK9lLfzXBxlPV4uS/KgdgN7Pmx04br07BfRkwM5PBjIL2gFYFvymB4+JT1/wLQjZptzPG3kmJPpbckD/zgsJCQkhg/QJ3PVUWJ5Zt98AAAAASUVORK5CYII=";

const About = () => {
  return (
    <Main>
      <HeroSection>
        <LeftContainer>
          <Heading>
            the <br />
            story
          </Heading>
          <SubHeading>
            CTW is the online community every learner and builder needs to be a
            part of.
          </SubHeading>
          <HeroDescription>
            CTW is a community-centric brand with the mission of educating and
            onboarding people to Web3 through fun experiences and inclusive
            approaches to education.
            <br />
            Our goal is to empower the curious to gain the knowledge they need
            from industry leaders to succeed in the digital world.
            {/* TODO */}
          </HeroDescription>
          <HeroButton onClick={() => window.open("/www.twitter.com/cryptotechwomen", "_blank")}>
            Explore our members benefits
          </HeroButton>
        </LeftContainer>
        <RightContainer>
          <CtwImg />
          <CallOut>
            <CallOutMessage>
              We have impacted 40,000+ individuals all around the world through
              our close-knit community, events, courses, and podcasts
            </CallOutMessage>
          </CallOut>
        </RightContainer>
      </HeroSection>
      <Numbers />
      <TeamContainer>
        <TeamWrapper>
          <TeamTitle>Meet the team</TeamTitle>
          <CardsContainer>
            <Card>
              <TeamImg src={'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685317593/gigi_nnpo4e.svg'} />
              <SocialContainer>
                <Logo
                  src={twLogo}
                  onClick={() =>
                    window.open(
                      "https://www.twitter.com/gianinaskarlett",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                />
                <Logo
                  src={linkedInLogo}
                  onClick={() =>
                    window.open(
                      "https://linkedin.com/in/gianinaskarlett",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                />
              </SocialContainer>
              <Name>Gianina Skarlett (Gigi)</Name>
              <Title>CEO</Title>
            </Card>
            <Card>
              <TeamImg src={'https://pbs.twimg.com/profile_images/1712521838616784897/FT0zXsTe_400x400.png'} />
              <SocialContainer>
                <Logo
                  src={twLogo}
                  onClick={() =>
                    window.open(
                      "https://twitter.com/SocialiteLifePR",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                />
                <Logo
                  src={linkedInLogo}
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/in/serena-bhatia-socialite/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                />
              </SocialContainer>
              <Name>Serena Baleja</Name>
              <Title>Partnerships</Title>
            </Card>

            <Card>
              <TeamImg src={'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685316221/joe_ahasaq.jpg'} />
              <SocialContainer>
                <Logo
                  src={linkedInLogo}
                  onClick={() =>
                    window.open(
                      "https://linkedin.com/in/gianinaskarlett",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                />
              </SocialContainer>
              <Name>Joe Sarkisian</Name>
              <Title>Finance Manager</Title>
            </Card>
            {/* <Card>
              <TeamImg src="https://res.cloudinary.com/w3-learn-academy/image/upload/v1688688757/ruz_tnxcad.jpg" />
              <SocialContainer>
                <Logo
                  src={twLogo}
                  onClick={() =>
                    window.open(
                      "https://twitter.com/RuzTV",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                />
              </SocialContainer>
              <Name>Ruz</Name>
              <Title>Social Media Manager</Title>
            </Card> */}
          </CardsContainer>
        </TeamWrapper>
      </TeamContainer>
      <CtwWrapper>
        <CTW />
      </CtwWrapper>
    </Main>
  );
};

export default About;

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

const Logo = styled.img.attrs((props: { src: string }) => {
  props.src.concat();
})`
  margin: 0 2px;
  width: 26px;
  height: 26px;
  cursor: pointer;
`;

const Main = styled.section``;

const CtwWrapper = styled.div`
  position: relative;
  width: 100%;
  background: white;
`;

const CTW = styled.img.attrs({
  src: 'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685316226/ctw-transparent-4_bydxmn.svg',
})`
  display: none;

  @media (min-width: 768px) {
    width: 500px;
    position: absolute;
    bottom: 0;
    display: block;
    right: 0;
  }
`;

// Hero Left

const LeftContainer = styled.div``;

const Heading = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  display: flex;
  align-items: center;
  letter-spacing: 1.75px;
  text-transform: uppercase;
  margin: 0 0 30px 0;

  @media (min-width: 768px) {
    font-size: 173px;
    line-height: 175px;
  }
`;

const SubHeading = styled.p`
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
  margin-top: 0;
  display: flex;
  align-items: center;
  letter-spacing: 0.35px;

  @media (min-width: 768px) {
    font-size: 35px;
  }
`;

const HeroSection = styled.div`
  padding: 20px;
  background-color: white;
  display: block;

  @media (min-width: 768px) {
    padding: 40px 100px;
    display: flex;
  }
`;

const HeroDescription = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  display: flex;
  align-items: center;
`;

const HeroButton = styled.button`
  color: #ffffff;
  font-size: 18px;
  padding: 20px;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  background-color: #1a1a1a;

  &:hover {
    background-color: #2b2b2b;
  }
`;

// Hero Right

const RightContainer = styled.div`
  @media (min-width: 768px) {
    width: 590px;
    margin-left: 30px;
  }
`;

const CtwImg = styled.img.attrs({
  src: 'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685317005/ctw-pfp_tgu9vz.png',
})`
  height: 300px;
  margin: 10px 0;

  @media (min-width: 768px) {
    height: 600px;
    margin-bottom: 20px;
  }
`;

const CallOut = styled.div`
  background-color: #ffffff;
  padding: 40px;
  border-radius: 3px;
`;

const CallOutMessage = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  text-align: center;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #1a1a1a;

  @media (min-width: 768px) {
    font-size: 30px;
  }
`;

// Team

const TeamContainer = styled.section`
  padding: 20px;
  background: white;

  @media (min-width: 768px) {
    padding: 80px 160px;
  }
`;

// Team Section

const TeamWrapper = styled.div``;

const TeamTitle = styled.h2`
  font-size: 30px;
  line-height: 45px;
  font-weight: 400;
  margin-top: 100px;
  align-items: center;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 43px;
    line-height: 54px;
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 0fr 0fr 0fr);
  justify-content: center;

  @media (min-width: 768px) {
    grid-template-columns: 0fr 0fr 0fr;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  width: 326px;
  background: #1fc2e9;
  margin: 20px;
`;

const TeamImg = styled.img.attrs((props: { src: string }) => {
  props.src.concat();
})`
  width: 250px;
  border-radius: 50%;

  @media (min-width: 768px) {
    width: 326px;
  }
`;

const Name = styled.p`
  font-size: 17px;
  line-height: 18px;
  /* identical to box height, or 106% */

  display: flex;
  align-items: center;
  letter-spacing: 3px;
  text-transform: uppercase;

  color: black;
  margin: 0;

  @media (min-width: 768px) {
  }
`;

const Title = styled.p`
  font-size: 17px;
  line-height: 18px;
  /* identical to box height, or 106% */

  display: flex;
  align-items: center;
  letter-spacing: 3px;
  text-transform: uppercase;

  color: black;
  text-align: center;
  margin: 8px 0 40px 0;
`;

// Spotlight

const MissionContainer = styled.section`
  background: white;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (min-width: 768px) {
    padding: 80px 160px;
  }
`;

const MissionLabel = styled.p`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1.4px;
  color: #1a1a1a;
`;

const MissionTitle = styled.h3`
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.55px;
  color: #ffffff;
  margin: 10px 0;

  @media (min-width: 768px) {
    font-size: 55px;
    line-height: 66px;
    margin: 0;
  }
`;
