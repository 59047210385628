import React from "react";
import styled from "styled-components";

const Partner = () => {
  return (
    <Main>
      <Title>Partner with us</Title>
      <Subtitle>
        Wether you’re a brand or a community, we want to hear
        from you!
      </Subtitle>
      <Grid>
        <CardWrapper>
          {/* <Img src={ctw} /> */}
          <CardTitle>Partner with CTW</CardTitle>
          <CardDescription>
            Partner with us to provide our community with access to events,
            giveaways, community memberships and more.
          </CardDescription>
          <CardButton
            onClick={() =>
              window.open(
                "mailto:partnerships@cryptotechwomennft.com?subject=Inquiry regarding Partnerships",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            Partnership Inquiry
          </CardButton>
        </CardWrapper>
        <CardWrapper>
          {/* <Img src={ctw} /> */}
          <CardTitle>Corporate Up-skilling</CardTitle>
          <CardDescription>
            Upgrade your team's Web3 knowledge with our tailored training
            programs. Expert instructors cover topics like Cryptocurrency,
            Decentralized Finance, and more. From project management to hands-on
            building, we have the talent to support your Web3 objectives.
          </CardDescription>
          <CardButton
            onClick={() =>
              window.open(
                "mailto:partnerships@cryptotechwomennft.com?subject=Inquiry regarding your Corporate Up-skilling",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            Upskilling Inquiry
          </CardButton>
        </CardWrapper>
        <CardWrapper>
          {/* <Img src={ctw} /> */}
          <CardTitle>Sponsor us</CardTitle>
          <CardDescription>
            Looking to promote your brand or protocol to our community of
            learners on CTW? We offer opportunities to
            create masterclasses, co-organize events, and support our mission.
            Inquire about sponsor perks!
          </CardDescription>
          <CardButton
            onClick={() =>
              window.open(
                "mailto:partnerships@cryptotechwomennft.com?subject=Inquiry regarding Sponsorship Partnerships",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            Sponsorship Inquiry
          </CardButton>
        </CardWrapper>
      </Grid>
    </Main>
  );
};

export default Partner;

const Grid = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

// Card

const CardWrapper = styled.div`
  padding: 2%;
  background-color: #ffffff;
  margin: 10px 0;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  @media (min-width: 768px) {
    width: 25%;
    margin: 0 10px;
  }
`;

const Img = styled.img.attrs((props: { src: string }) => {
  props.src.concat();
})`
  width: 100%;
  height: 50%;
  object-position: 50% 50%;
  object-fit: cover;
`;

const CardTitle = styled.p`
  font-size: 20px;
  margin: 10px 0 0 0;
  font-weight: 500;

  @media (min-width: 768px) {
    margin: 20px 0 0 0;
  }
`;

const CardDescription = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  min-height: 138px;

  @media (min-width: 768px) {
  }
`;

const CardButton = styled.button`
  padding: 10px 35px;
  border-radius: 30px;
  border: 0;
  font-size: 12px;
  margin-top: 10px;
  font-weight: 400;
  color: white;
  margin-left: 15px;
  background-color:  #1a1a1a;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: #2b2b2b;
  }
}
`;

const Main = styled.section`
  background-color: white;
  padding: 20px;
  min-height: 69vh;

  @media (min-width: 768px) {
    padding: 100px 160px;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 60px;
  margin: 0 0 10px 0;
  text-align: center;
  align-items: center;
  letter-spacing: -0.6px;
  text-transform: uppercase;
  width: fit-context;
  color: #1a1a1a;

  @media (min-width: 768px) {
    font-size: 60px;
  }
`;

const Subtitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  margin: 0 0 15px 0;
  align-items: center;
  text-align: center;
  letter-spacing: -0.8px;
  color: #1fc2e9;

  @media (min-width: 768px) {
    font-size: 32px;
    margin: 0 0 40px 0;
  }
`;
