import React from "react";
import styled from "styled-components";

const SocialProof = () => {
  return (
    <Main>
      <Logo src={'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685316225/forbes_jdmyp8.svg'} />
      <Logo src={'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685316219/coindesk_blcbf1.svg'} />
      <Logo src={'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685316227/hackernoon_o4vecr.svg'} />
      <Logo src={'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685316223/hubspot_papgz2.svg'} />
      <Logo src={'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685316225/nftlately_jsdcln.svg'} />
      <Logo src={'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685316225/sme_uij1al.svg'} />
      <Logo src={'https://res.cloudinary.com/w3-learn-academy/image/upload/v1685316223/nft365_ocel1a.svg'} />
    </Main>
  );
};

export default SocialProof;

const Main = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: white;

  @media (min-width: 768px) {
    padding: 20px 150px;
  }
`;

const Logo = styled.img.attrs((props: { src: string }) => {
  props.src.concat();
})`
  height: 45px;
  width: 45px;

  @media (min-width: 768px) {
    height: 120px;
    width: 120px;
  }
`;
