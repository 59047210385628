import React from "react";
import { Routes, Route } from "react-router-dom";
import styled from "styled-components";
// Components
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import About from "./pages/About";
import LandingPage from "./pages/LandingPage";
import Partner from "./pages/Partner";
import Perks from "./pages/Perks";
import BurnLandingPage from "./pages/BurnLandingPage";
import Activations from "./pages/Activations";
import Experience from "./pages/Experience";
import PressPage from './pages/Press';

function App() {
  return (
    <AppStyles>
      <NavBar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/press" element={<PressPage />} />
        {/* <Route path="/experiences" element={<BurnLandingPage />} /> */}
        {/* <Route path="/experience" element={<Experience />} /> */}
        {/* <Route path="/all-experiences" element={<Activations />} /> */}
        <Route path="/perks" element={<Perks />} />
        <Route path="/partner" element={<Partner />} />
      </Routes>
    </AppStyles>
  );
}

export default App;

const AppStyles = styled.div`    
  margin: 0;
  padding: 0;
  min-height: 100vh;
`;
