import react from 'react';
import styled from 'styled-components';

const pressList = [
    {name: `Taylor Swift's Direct-To-Fan Engagement Embraces Web3 Principles`, 
    url: 'https://www.forbes.com/sites/digital-assets/2023/10/27/taylor-swifts-direct-to-fan-engagement-embraces-web3-principles/?sh=c12cfc6135d2',
    media: 'Forbes',},
    {name: `From Coding to Culture: Tips for Success in Web3, With Crypto Tech Women`, 
    url: 'https://www.coindesk.com/podcasts/women-who-web3/from-coding-to-culture-tips-for-success-in-web3-with-crypto-tech-women/',
    media: 'Coindesk',},
    {name: `Women in Web3: Gianina Skarlett on Creating Inclusive Environments and W3 Learn Academy`, 
    url: 'https://hackernoon.com/women-in-web3-gianina-skarlett-on-creating-inclusive-environments-and-w3-learn-academy',
    media: 'Hackernoon',},
    {name: `How Are Crypto Tech Women Changing the Web3 Space`, 
    url: 'https://uk-podcasts.co.uk/podcast/cmo-stories/s2-e24-how-are-crypto-tech-women-changing-the-web3',
   media: 'uk-podcasts'},
    {name: `Forbes: Diez influencers latinoamericanos que deberías seguir para aprender sobre NFTs `, 
    url: 'https://www.forbesargentina.com/innovacion/diez-influencers-latinoamericanos-deberias-seguir-aprender-sobre-nfts-n18080',
   media: 'Forbes',},
    {name: `Women in Web 3: Gianina Skarlett, creator of Crypto Tech Women`, 
    url: 'https://thecryptoconnection.substack.com/p/women-in-web-3-gianina-skarlet-creator',
   media: 'thecryptoconnection',},
    {name: `The Women Behind Crypto Tech Women Making Waves In the NFT Space`, 
    url: 'https://nftlately.com/the-women-behind-crypto-tech-women-making-waves-in-the-nft-space/',
   media: 'nftlately',},
    {name: `CTW on Working Women Report`, 
    url: 'https://www.youtube.com/watch?app=desktop&v=jk9MmAbYr3I&ab_channel=WorkingWomanReport',
   media: 'Working Women Report',},
];
const PressPage = () => {
    return (
        <Wrapper>
            <Heading>Press</Heading>
            <ListContainer>
                {
                    pressList.map((item, index) => {
                        return (
                            <ListItem key={index}  onClick={() => window.open(item.url, "_blank", "noopener,noreferrer")}>
                                <ListName>{item.name}</ListName>
                                <Tag>{item.media}</Tag>
                            </ListItem>
                        )
                    })
                }
            </ListContainer>
        </Wrapper>

    )
}

export default PressPage;

const Tag = styled.div`
    background-color: black;
    font-size: 14px;
    color: white;
    width: 132px;
    padding: 5px;
    border-radius: 6px;
    text-align: center; 
`;

const Wrapper = styled.section`
    background-color: white;
    padding: 20px;
    min-height: 69vh;

    @media (min-width: 768px) {
    padding: 100px 160px;
    }
`;

const Heading = styled.h1`
font-family: "Inter";
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 45px;
display: flex;
align-items: center;
letter-spacing: 1.75px;
text-transform: uppercase;
margin: 0 0 30px 0;

@media (min-width: 768px) {
  font-size: 173px;
  line-height: 175px;
}
`;

const ListContainer = styled.div``;

const ListItem = styled.div`
    cursor: pointer;
    padding: 40px;
    margin: 10px 0;
    border: 1px solid black;
    border-radius: 6px;

    &:hover {
        background-color: #f2f2f2;
    }
`;

const ListName = styled.p`
    font-size: 16px;
    font-weight: 600;
`;